import request from '@/utils/request';

// POST /sysCompanyCity/delete
//   删除
//
// GET /sysCompanyCity/getInfoByComId/{comId}
// 根据Id获得信息
//
// GET /sysCompanyCity/list
// 获得列表
//
// POST /sysCompanyCity/save
// 保存
//
// POST /sysCompanyCity/update
// 修改

export function sysCompanyCityDel(data) {
  return request({
    url: '/sysCompanyCity/delete',
    method: 'post',
    data,
  });
}

export function sysCompanyCityList(query) {
  return request({
    url: '/sysCompanyCity/list',
    method: 'get',
    params: query
  });
}

export function sysCompanyCitySave(data) {
  return request({
    url: '/sysCompanyCity/save',
    method: 'post',
    data
  });
}

export function sysCompanyCityUpdate(data) {
  return request({
    url: '/sysCompanyCity/update',
    method: 'post',
    data
  });
}

export function sysCompanyCityInfo(id) {
  return request({
    url: `/sysCompanyCity/getInfoByComId/${id}`,
    method: 'get',
  });
}

// POST /sysCompany/company/delete
//   删除分公司
//
// POST /sysCompany/company/save
// 保存分公司
//
// POST /sysCompany/company/update
// 修改分公司
//
// POST /sysCompany/dept/delete
//   删除部门
//
// POST /sysCompany/dept/save
// 保存部门
//
// POST /sysCompany/dept/update
// 修改部门

export function sysCompanyCompanyDelete(data) {
  return request({
    url: '/sysCompany/company/delete',
    method: 'post',
    data
  });
}

export function sysCompanyCompanySave(data) {
  return request({
    url: '/sysCompany/company/save',
    method: 'post',
    data
  });
}

export function sysCompanyCompanyUpdate(data) {
  return request({
    url: '/sysCompany/company/update',
    method: 'post',
    data
  });
}

export function sysCompanyDeptDelete(data) {
  return request({
    url: '/sysCompany/dept/delete',
    method: 'post',
    data
  });
}

export function sysCompanyDeptSave(data) {
  return request({
    url: '/sysCompany/dept/save',
    method: 'post',
    data
  });
}

export function sysCompanyDeptUpdate(data) {
  return request({
    url: '/sysCompany/dept/update',
    method: 'post',
    data
  });
}

export function sysCompanyGetTree(id) {
  return request({
    url: '/sysCompany/getTree',
    method: 'get',
  });
}

// POST /sysCompanyUser/delete
//   删除用户
//
// GET /sysCompanyUser/info/{userId}
// 根据Id获得用户信息
//
// GET /sysCompanyUser/list
// 用户分页列表
//
// POST /sysCompanyUser/save
// 保存用户
//
// GET /sysCompanyUser/selectByComId/{comId}
// 根据分公司id查所有用户
//
// POST /sysCompanyUser/update
// 修改用户

export function sysCompanyUserDelete(data) {
  return request({
    url: '/sysCompanyUser/delete',
    method: 'post',
    data
  });
}

export function sysCompanyUserInfo(id) {
  return request({
    url: `/sysCompanyUser/info/${id}`,
    method: 'get',
  });
}

export function sysCompanyUserList(query) {
  return request({
    url: '/sysCompanyUser/list',
    method: 'get',
    params: query
  });
}

export function sysCompanyUserSave(data) {
  return request({
    url: '/sysCompanyUser/save',
    method: 'post',
    data
  });
}

export function sysCompanyUserSelectByComId(id) {
  return request({
    url: `/sysCompanyUser/selectByComId/${id}`,
    method: 'get',
  });
}

export function sysCompanyUserUpdate(data) {
  return request({
    url: '/sysCompanyUser/update',
    method: 'post',
    data
  });
}

export function sysCompanyRoleSelect() {
  return request({
    url: '/sysCompanyRole/select',
    method: 'get',
  });
}

// POST /sysCompanyRole/delete
//   删除
//
// GET /sysCompanyRole/info/{roleId}
// 根据Id获得角色信息
//
// GET /sysCompanyRole/list
// 获得列表
//
// GET /sysCompanyRole/menu/list
// 所有菜单列表
//
// POST /sysCompanyRole/save
// 保存
//
// GET /sysCompanyRole/select
// 角色列表
//
// POST /sysCompanyRole/update
// 修改

export function sysCompanyRoleDelete(data) {
  return request({
    url: '/sysCompanyRole/delete',
    method: 'post',
    data
  });
}

export function sysCompanyRoleList(query) {
  return request({
    url: '/sysCompanyRole/list',
    method: 'get',
    params: query
  });
}

export function sysCompanyRoleMenuList() {
  return request({
    url: '/sysCompanyRole/menu/list',
    method: 'get'
  });
}

export function sysCompanyRoleInfo(roleId) {
  return request({
    url: `/sysCompanyRole/info/${roleId}`,
    method: 'get',
  });
}

export function sysCompanyRoleSave(data) {
  return request({
    url: '/sysCompanyRole/save',
    method: 'post',
    data,
  });
}

export function sysCompanyRoleUpdate(data) {
  return request({
    url: '/sysCompanyRole/update',
    method: 'post',
    data,
  });
}

// POST /sysCompanyWorkflow/delete
//   删除
//
// GET /sysCompanyWorkflow/info/{id}
// 根据Id获得角色信息
//
// GET /sysCompanyWorkflow/list
// 获得列表
//
// POST /sysCompanyWorkflow/save
// 保存
//
// POST /sysCompanyWorkflow/update
// 修改

export function sysCompanyWorkflowDelete(data) {
  return request({
    url: '/sysCompanyWorkflow/delete',
    method: 'post',
    data,
  });
}

export function sysCompanyWorkflowSave(data) {
  return request({
    url: '/sysCompanyWorkflow/save',
    method: 'post',
    data,
  });
}

export function sysCompanyWorkflowUpdate(data) {
  return request({
    url: '/sysCompanyWorkflow/update',
    method: 'post',
    data,
  });
}

export function sysCompanyWorkflowList(query) {
  return request({
    url: '/sysCompanyWorkflow/list',
    method: 'get',
    params: query
  });
}

export function sysCompanyWorkflowInfo(id) {
  return request({
    url: `/sysCompanyWorkflow/info/${id}`,
    method: 'get',
  });
}
