import request from '@/utils/request';

export function listCityRegions(id) {
  return request({
    url: '/common/city/listCityRegions',
    method: 'get'
  });
}

export function getNativeList(id) {
  return request({
    url: '/common/native/list',
    method: 'get'
  });
}

export function getCityTree(id) {
  return request({
    url: '/common/city/getTree',
    method: 'get'
  });
}

export function getRoleList() {
  return request({
    url: '/sys/role/select',
    method: 'get'
  });
}

export function getMenuList() {
  return request({
    url: '/sys/menu/list',
    method: 'get'
  });
}
